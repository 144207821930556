// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "23.4.0",
  "buildNumber": "17731",
  "gitSha": "4deb96d823b921419680560be080588e900d406e",
  "fullVersion": "23.4.0-17731",
  "formattedVersion": "23.4.0 (17731-4deb96d823b921419680560be080588e900d406e)",
  "copyrightYear": "2023"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/23.4.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/23.4.0/${stripLeadingPrefix(suffix, '#')}`
}
    
